/* eslint-disable no-undef */
/**
 *http响应拦截器
 */
 import axios from "axios";
import { Toast } from "vant";
import router from "../router/index"; //路由
 // console.log(router);
 if(process.env.NODE_ENV === 'development'){
   axios.defaults.baseURL = 'http://jhzapi.njjhz.com/';
  // axios.defaults.baseURL = " http://192.168.10.31:25102/"
   } else {
     axios.defaults.baseURL = config.baseUrl
   }
 ///request拦截器
 axios.interceptors.request.use(
    (req) => {
        const params = router.currentRoute.query;
     req.params = Object.assign({},params,  req.params, {
       t: Math.random(),
     });
     return req;
   },
   (error) => {
     return Promise.reject(error);
   }
 );
 
 //response拦截器
 axios.interceptors.response.use(
   (res) => {
     if(res.data.status!=0 && res.data.status!=60002){
       Toast(res.data.msg)
       return new Error(res.data.msg);
     }else{
       return res.data;
     }
   },
   (error) => {
     return new Error(error); // 返回错误信息
   }
 );
 