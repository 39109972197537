import Vue from 'vue'
let isLocalStorageAva = undefined
/**
 * this is a polyfill of localstore
 * 使用Cookie存储的Polyfill
 * @type {Storage}
 */
const localstore = {
  /**
   * @deprecated 弃用
   */
  // removehistory () {
  //   // 不行就从Cookie中读取
  //   let all = document.cookie
  //   let allKv = all.split(';')
  //   let objs = allKv.map(item => {
  //     let kv = item.split('=')
  //     return {
  //       key: decodeURIComponent(kv[0].trim()),
  //       value: kv.length > 1 ? decodeURIComponent(kv[1].trim()): undefined
  //     }
  //   })
  //   objs.forEach(item => {
  //     let date = new Date()
  //     date.setDate(date.getDate() - 30)
  //     document.cookie = encodeURIComponent(item.key) + '=1' + ';expires=' + date.toUTCString()      
  //   })
  // },
  // 测试localStory是否可用
  checkLocalStory () {
    //this.removehistory()
    if (isLocalStorageAva === undefined) {
      try {
        window.localStorage.setItem('__test_local__', 'test')
        window.localStorage.removeItem('__test_local__')
        isLocalStorageAva = true
        return true
      } catch (error) {
        Vue.$log.warn(error)
        isLocalStorageAva = false
        return false
      }
    }
    return isLocalStorageAva
  },
  getItem (key) {
    if (this.checkLocalStory()) {
      return window.localStorage.getItem(key)
    }
    // 不行就从Cookie中读取
    let all = document.cookie
    let allKv = all.split(';')
    let objs = allKv.map(item => {
      let kv = item.split('=')
      return {
        key: decodeURIComponent(kv[0].trim()),
        value: kv.length > 1 ? decodeURIComponent(kv[1].trim()): undefined
      }
    })
    let finded = objs.findIndex(item => item.key === key)
    return objs[finded] ? objs[finded].value : null
  },
  setItem (key, value) {
    if (this.checkLocalStory()) {
      return window.localStorage.setItem(key, value)
    }
    let date = new Date()
    date.setDate(date.getDate() + 30)
    document.cookie = encodeURIComponent(key) + '=' + encodeURIComponent(value) + ';expires=' + date.toUTCString() + ';path=/'
  },
  clear () {
    if (this.checkLocalStory()) {
      return window.localStorage.clear()
    }
  },
  removeItem (key) {
    if (this.checkLocalStory()) {
      return window.localStorage.removeItem(key)
    }
    let date = new Date()
    date.setDate(date.getDate() - 30)
    document.cookie = encodeURIComponent(key) + '=1' + ';expires=' + date.toUTCString() + ';path=/'
  },
  key (index) {
    if (this.checkLocalStory()) {
      return window.localStorage.key(index)
    }
    // 不行就从Cookie中读取
    let all = document.cookie
    let allKv = all.split(';')
    let objs = allKv.map(item => {
      let kv = item.split('=')
      return {
        key: decodeURIComponent(kv[0].trim()),
        value: kv.length > 1 ? decodeURIComponent(kv[1].trim()): undefined
      }
    })
    if (index < 0 || index > objs.length) {
      throw 'index out of bound'
    }
    return objs[index].key
  }
}

Object.defineProperty(localstore, 'length', {
  get () {
    if (this.checkLocalStory()) {
      return window.localStorage.length
    }
    // 不行就从Cookie中读取
    let all = document.cookie
    let allKv = all.split(';')
    let objs = allKv.map(item => {
      let kv = item.split('=')
      return {
        key: decodeURIComponent(kv[0].trim()),
        value: kv.length > 1 ? decodeURIComponent(kv[1].trim()): undefined
      }
    })
    return objs.length
  }
})

export default localstore
