// import { isNull } from 'util'
import localStore from '../../utils/localstore_polyfill'

/**
 * @type {import("vuex").Module<{historys: string[]}, {historys:string[]}>}
 */
const historyStore = {
  state: {
    /**
     * @type {string[]}
     */
    historys: [],
    mysenter:0,
    cententlist:0,
    myentertable:-100
  },
  mutations: {
    setHistory (state, history) {
      if (history) {
        state.historys = history
      }
    },
    myentertablelist(state, history) {
      if (history) {
        state.myentertable = Number(history)
      }
    },
    mysenteradd(state, mysenter){
      if (history) {
        state.mysenter = mysenter
      }
    },
    cententlistadd(state, cententlist){
      if (history) {
        state.cententlist = Number(cententlist)
      }
    }
  },
  actions: {
    myentertablebtn(context, history) {
      context.commit('myentertablelist', history)
      localStore.setItem('myentertablelist', history)
    },
    getmyentertablebtn(context) {
      let strHistory = localStorage.getItem('myentertablelist')?localStorage.getItem('myentertablelist'):-100;
      context.commit('myentertablelist', strHistory)
    },
    setHistory (context, history) {
      context.commit('setHistory', history)
      localStore.setItem('search-history', JSON.stringify(history))
    },
    // 游戏中心引导
    setmysenteradd(context, mysenter){
      context.commit('mysenteradd', mysenter)
      localStorage.setItem('mysenteradd',mysenter)
    },
    getmysenteradd (context) {
      let strHistory = localStorage.getItem('mysenteradd')?localStorage.getItem('mysenteradd'):0;
      context.commit('mysenteradd', JSON.parse(strHistory))
    },
    // 我参与的引导
    setcententlist(context, cententlist){
      context.commit('cententlistadd', Number(cententlist))
      localStorage.setItem('cententlist',String(cententlist))
    },
    getcententlist (context) {
      let strHistory = localStorage.getItem('cententlist')?localStorage.getItem('cententlist'):0;
      context.commit('cententlistadd', (strHistory))
    },
    addHistory (context, his) {
      let my = Array.from(context.state.historys)
      let index = my.indexOf(his)
      if (index >= 0) {
        my.splice(index, 1)
      }
      if(his){
        my.push(his)
      }
      context.commit('setHistory', my)
      localStore.setItem('search-history', JSON.stringify(my))
      console.log(context.state.historys)
    },
    clearHistory (context) {
      context.commit('setHistory', [])
      localStore.removeItem('search-history')
    },
    readHistory (context) {
      let strHistory = localStore.getItem('search-history')
      context.commit('setHistory', JSON.parse(strHistory))
    }
  }
}

export default historyStore
