import Vue from 'vue'
import Vuex from 'vuex'
import history from './modules/searchHistory'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    history,
  },
  // eslint-disable-next-line no-undef
  strict: process.env.NODE_ENV !== 'production'
})

export default store
