import axios from "axios";
import Vant from "vant";
import 'vant/lib/index.css';
import Vue from 'vue';
import App from './App.vue';
import './networking';
import router from './router';
import store from './store';
import 'babel-polyfill'
import Es6Promise from 'es6-promise'
Es6Promise.polyfill();
Vue.config.productionTip = false
Vue.use(Vant);
new Vue({
  router,
  store,
  axios,
  render: h => h(App)
}).$mount('#app')
